import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About us</h2>
          <p>
          Integrity Over Everything was brought into inception with the intention on bringing the focus and intensity of training back to moving well. For too long now, maximal load and speed has been lauded above Integrity of movement. We believe that mastery of movement quality will yield a higher return in performance, reduce risk of injury and increase all health markers.</p>
          <p>The Owners and Coaches; Alex Parkinson and Connor Oates have a combined 10 years of experience in coaching. Alex found a love for gymnastics through his partaking of Crossfit and likewise, Connor also found a love for weightlifting having started in Crossfit.</p>
          <p>The aim of Integrity Over Everything, is to reach as many people as possible with our message and assist athletes of all levels in moving, living and looking better. This will be achieved through the delivery of a first class online program (delivered through the phenomenal True Coach app) and through in person full day workshops.</p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Programming</h2>
          <p>
          Our programme consists of 3 weightlifting sessions (Monday, Wednesday and Friday), and 2 Gymnastic sessions (Tuesday and Saturday) per week. These sessions are delivered through the fantastic app- True Coach. Your program will come complete with tutorial videos and detailed descriptions of the expectations within those exercises. You can upload videos for feedback directly to TrueCoach and we will review this videos each week.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Seminars</h2>
          <p>
          We offer day long workshops where we will look to develop you as an athlete within weightlifting and gymnastics. The day will be split into 2 parts:</p>
          <ul>
            <li>AM: Focus on Snatch, Clean and Jerk. Improving technique, awareness and consistency of movement</li>
            <li>PM: Focus on developing Hollow and Arch shapes, progressing to Pull-Up and Handstand Push-Up work</li>
          </ul> 
          <p>We are looking to bring these to gyms nationally and internationally.
          </p>
          {close}
        </article>

        <article
          id="sample"
          className={`${this.props.article === 'sample' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Sample Workouts</h2>
          <p>
          <strong>Gymnastics Day 1:</strong>
          <p>Establish 1rm Strict Pull-Up</p>
          <p>E.g if bodyweight is 80kg and athlete can perform only 1 BW pull up, score will be 80. If athlete can achieve pull up with a 10kg plate attached then score will be 90 and so on.</p>
          <p>If no-pull ups can be achieved then score is 0 and scales will be used for proceeding programming.</p>
          <br/>
          <p>Light conditioning piece:</p>
          <p>Accumulate 2 mins in Hollow body position. Integrity of exercise must be maintained, once integrity is broken, athlete must perform 250m row. Score is time.</p>
          <br/><br/>
          <strong>Gymnastics Day 2:</strong>
          <p>Establish max 1rm parallel bar dip. Athlete must achieve shoulder below elbow height and must hit full extension. As with the pull ups, the score is bodyweight + any extra weight added.</p>
          <p>If no dips can be performed at bodyweight then score is 0 and athlete will perform scales for proceeding programming.</p>
          <br/>
          <p>Light conditioning piece:</p>
          <p>Accumulate 10x10m lengths Farmer carries w’ KBs 2x24/16kg</p>
          <p>Each time athlete breaks, athlete must perform 10 burpees.</p>
          <p>Score is time.</p>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
           <form
                  method="post"
                  name="contact-form"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  action="/thankyou"
                >
                  <input
                    type="hidden"
                    name="contact-form"
                    value="contact-form"
                  />
                  <div hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" />
                    </label>
                  </div>
                  <div className="row uniform 50%">
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </div>
                    <div className="6u 12u$(xsmall)">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="12u">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Message"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <ul className="actions">
                    <li className="form-submit">
                      <button type="submit" className="button">
                        Submit
                      </button>
                    </li>
                  </ul>
                </form>
          <ul className="icons">
            <li>
              <a href="https://instagram.com/integrityovereverything" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/sdeakin93"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  instagram: PropTypes.string,
}

export default Main
